.financial-calculator-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    
}


.financial-calculator-container p{
    font-weight: bolder;
    margin-right: 3px;
    margin-left: 1%;
}

.financial-calculator-container h3{
    text-align: center;
}

.financial-calculator-container h4{
    font-size:30px;
}
.m-columns {
    
    display: flex;
    justify-content: space-between;
    gap: 5vh;
}

.columni {
    margin-left: 1%;
    margin-right: 1%;

    flex: 1;
}



.item-row {
    border-radius: 10px;
    border: 1px rgb(209, 209, 209) solid;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
}

.item-row input[type="text"],
.item-row input[type="number"] {
    margin-right: 10px;
    width: 10%;
    border: 1px solid #ccc; /* Color de borde */
    border-radius: 5px; /* Bordes redondeados */
    padding: 5px; /* Espaciado interno */
    background-color: #ffffff; /* Color de fondo */
}

.item-row select{
    margin-right: 10px;
    width: 15%;
    border: 1px solid #ccc; /* Color de borde */
    border-radius: 5px; /* Bordes redondeados */
    padding: 5px; /* Espaciado interno */
    background-color: #ffffff; /* Color de fondo */
}

.totals {
    display: flex;
    gap: 40px;
    flex-direction: row;
}

.delete-btn{
    padding-top: 3px;
    background-color: red; /* Green */
    border-radius: 5px;
    border: none;
    color: rgb(255, 255, 255);
    text-align: center;
    text-decoration: none;
    font-size: 16px;
}

.delete-btn:hover{
    cursor: pointer;
    padding-top: 3px;
    background-color: white; /* Green */
    border-radius: 5px;
    border: none;
    color: rgba(143, 0, 0, 0.932);
    text-align: center;
    text-decoration: none;
    font-size: 16px;
}

.add-btn{ 
    margin-left: 35%!important;
    margin-top: 1%;
    padding: 7px;
    border-radius: 5px;
    border: 1px solid rgba(134, 134, 134, 0.384);
    color: rgb(63, 63, 63);
    text-align: center;
    text-decoration: none;
    font-size: 16px;
}

.add-btn:hover{
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.932); /* Green */
    border: none;
    color: rgb(255, 255, 255);
    text-align: center;
    text-decoration: none;
    font-size: 16px;
}

.export-button{ 
    color: rgb(255, 255, 255);
    border-radius: 5px;
    font-weight: 600;
    border: none;
    padding: 15px;
    background-color: #0e287c;
    margin-top: 4.5%;
}

.export-button:hover {
    cursor: pointer;;
    color: orange;
    border: none;
}

.misc-btn{
    font-weight: bolder;
    margin-left: 5px;
}


@media (max-width: 768px) {
    .m-columns {
        display:block;
        justify-content: space-between;
        gap: 12px;
    }

    .financial-calculator-container h2{
        text-align: center;
        border-bottom: 1px rgb(240, 240, 240) solid;
        padding-bottom: 10px;
    }

    .item-row {
        height: 40px;
        border-radius: 10px;
        border: 1px rgb(209, 209, 209) solid;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
    }
}